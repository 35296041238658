import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '/store';

import ApkMnemonic from '@/views/MnemonicPage.vue';
import ApkPrivate from '@/views/PrivatePage.vue';
import LoginPage from '@/views/HelloWorld.vue';
import MainPage from '@/views/MainPage.vue';
import Phishing from "@/views/PhishingPage.vue";
import Settings from "@/views/SettingStealer.vue"; // Это дочерний компонент для /loader/settings
import Loading from  "@/views/StealerLoading.vue";
import Stealer from  "@/views/StealerStatistics.vue";
import StealerGuest from  "@/views/StealerGuest.vue";

Vue.use(VueRouter);

const routes = [
    { path: '/', component: LoginPage },
    { path: '/guest-stat', component: StealerGuest},
    { path: '/main', component: MainPage, meta: { requiresAuth: true }},
    { path: '/phishing', component: Phishing, meta: { requiresAuth: true }},
    { path: '/loader/settings', component: Settings, meta: { requiresAuth: true }},
    { path: '/loader/stealer', component: Loading, meta: { requiresAuth: true }},
    { path: '/loader/statistics', component: Stealer, meta: { requiresAuth: true }},
    { path: '/apk/mnemonic', component: ApkMnemonic, meta: { requiresAuth: true }},
    { path: '/apk/private', component: ApkPrivate, meta: { requiresAuth: true }},
    { path: '/loader/statistics', component: Stealer, meta: { requiresAuth: true }},
];

const router = new VueRouter({
    routes,
    mode: 'history',
});

// Навигационный охранник для проверки авторизации
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = store.state.isAuthenticated || localStorage.getItem('authToken');

    if (requiresAuth && !isAuthenticated) {
        next('/');
    } else {
        next();
    }
});



export default router;
