<template>
  <v-container fluid>
    <v-btn class="mb-3" @click="addTile">{{$t('add')}}</v-btn>
    <v-row>
      <v-col v-for="(tile, index) in tiles" :key="tile.id" cols="12" sm="6" md="4">
        <v-card class="pa-3">
          <v-card-title>
            {{ $t('service') }} {{ index + 1 }}
          </v-card-title>
          <v-card-text>
            <v-text-field class="mb-3" :label="$t('enterlink')" v-model="tile.link"></v-text-field>
            <v-checkbox v-model="tile.enabled" :label="$t('enable')"></v-checkbox> <!-- Добавленный чекбокс -->
            <v-select
                :items="countryList"
                item-text="name"
                item-value="code"
                :label="$t('entercountry')"
                v-model="tile.country"
                multiple
                :hint="$t('manycountry')"
                persistent-hint>
              <template v-slot:selection="{ item, index }">
                <v-chip
                    v-if="index === 0"
                    :key="`${item.code}-chip`"
                    label
                    small
                >
                  {{ tile.country.join(', ') }}
                </v-chip>
              </template>
            </v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="tile.isNew" @click="saveTile(tile)">Сохранить</v-btn>
            <v-btn @click="editTile(tile)">{{$t('edit')}}</v-btn>
            <v-btn @click="deleteTile(tile.id)">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
/* eslint-disable no-alert */
import countries from 'i18n-iso-countries';
import 'i18n-iso-countries/langs/en.json';
import axios from 'axios';
import enMessages from '/i18n/en';
import ruMessages from '/i18n/ru';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
  data() {
    return {
      tiles: [],
      countryList: Object.entries(countries.getNames('en')).sort((a, b) => a[1].localeCompare(b[1])).map(([code, name]) => ({ code, name })),
    };
  },
  created() {
    this.loadTiles();
  },
  methods: {
    addTile() {
      this.tiles.push({ id: this.generateId(), link: '', country: [], enabled: false, isNew: true }); // added enabled property
    },
    async loadTiles() {
  try {
    // Получаем токен из состояния Vuex store
    const token = this.$store.state.token;

    // Проверяем наличие токена перед выполнением запроса
    if (!token) {
      console.error("2No authentication token available.");
      return;
    }

    // Используем window.location.origin для получения текущего домена


    const hostname = window.location.hostname;
        const apiURL = `https://api.${hostname}`;

    const response = await axios.get(`${apiURL}/v1/give/settings`, {
      headers: {
        'Authorization': `Bearer ${token}` // Включаем токен в заголовки запроса
      }
    });

    this.tiles = response.data.map(tile => ({
      id: tile._id,
      link: tile.service,
      country: tile.country.split(', ').map(item => item.trim()),
      enabled: tile.enabled || false,  // added enabled property
      isNew: false
    }));
  } catch (error) {
    console.error("Error loading tiles:", error.message);
  }
},
async saveTile(tile) {
    const token = this.$store.state.token; // Получаем токен из Vuex store
    const url = new URL(tile.link);
    const pathParts = url.pathname.split('/');
    const exe = pathParts[pathParts.length - 1];

    const tileData = {
      time: new Date().toISOString(),
      service: tile.link,
      exe: exe,
      country: tile.country.join(', '),
      enabled: tile.enabled // added enabled property
    };

    try {

    // Используем window.location.origin для получения текущего домена
    const hostname = window.location.hostname;
        const apiURL = `https://api.${hostname}`;

    const response = await axios.post(`${apiURL}/v1/add/settings`, tileData, {
        headers: {
          'Authorization': `Bearer ${token}` // Добавляем токен в заголовки запроса
        }
      });
      if (response.data.success) {
        console.log("Tile successfully saved!");
        tile.isNew = false;
      } else {
        console.error("Error saving the tile:", response.data.message);
      }
    } catch (error) {
      console.error("Error saving the tile:", error.message);
    }
  },

    async editTile(tile) {
    const token = this.$store.state.token; // Получаем токен из Vuex store
    const tileData = {
      service: tile.link,
      country: tile.country.join(', '),
      enabled: tile.enabled // added enabled property
    };

    try {

      // Используем window.location.origin для получения текущего домена

      const hostname = window.location.hostname;
        const apiURL = `https://api.${hostname}`;

      await axios.patch(`${apiURL}/v1/edit/settings/${tile.id}`, tileData, {
        headers: {
          'Authorization': `Bearer ${token}` // Добавляем токен в заголовки запроса
        }
      });
      console.log("Tile successfully updated!");
    } catch (error) {
      console.error("Error updating the tile:", error.message);
    }
  },

    async deleteTile(tileId) {
    const token = this.$store.state.token; // Получаем токен из Vuex store
    try {

      // Используем window.location.origin для получения текущего домена

      const hostname = window.location.hostname;
        const apiURL = `https://api.${hostname}`;

      await axios.delete(`${apiURL}/v1/remove/settings/${tileId}`, {
        headers: {
          'Authorization': `Bearer ${token}` // Добавляем токен в заголовки запроса
        }
      });
      this.tiles = this.tiles.filter(tile => tile.id !== tileId);
      console.log("Tile successfully deleted!");
    } catch (error) {
      console.error("Error deleting the tile:", error.message);
    }
  },

  generateId() {
    return Math.random().toString(36).substr(2, 9);
  }
},
  i18n: {
    messages: {
      en: enMessages,
      ru: ruMessages
    }
  }
};
</script>





