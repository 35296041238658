export default {
    password: 'Пароль',
    login: 'Войти',
    changeTheme: 'Сменить тему',
    changeLanguage: 'Сменить язык',
    passwordRequired: 'Поле "Пароль" обязательно для заполнения',
    passwordLength: 'Пароль должен быть не меньше 8 символов',
    stats: 'Статистика',
    phishing: 'Фишинг',
    apk: 'Апк',
    loader: 'Лоадер',
    settings: 'Настройки',
    stealer: 'Стиллер',
    profile: 'Мой профиль',
    mySettings: 'Мои настройки',
    logout: 'Выйти',
    drawer: 'Ящик',
    home: 'Главная',
    load: 'Загрузить',
    enterlink: 'Укажите ссылку',
    add: 'Добавить',
    entercountry: 'Укажите страну',
    manycountry: 'Вы можете выбрать несколько стран',
    save: 'Сохранить',
    edit: 'Редактировать',
    delete: 'Удалить',
    chooseClient: 'Выберите клиент',
    service: 'Сервис',
    loadFile: 'Загрузить файл'
};
