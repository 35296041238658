<template>
  <div>
    <AppHeader
        :user-menu-items="userMenuItems"
        @switch-theme="switchTheme"
        @toggle-drawer="drawer = !drawer"
        @menu-click="handleMenuClick"
    />

    <StealerTable :phishingData="phishingData" class="ma-3"/>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import StealerTable from '../components/StealerTable.vue'
//import axios from 'axios';

export default {
  components: {
    AppHeader,
    StealerTable,
  },
  data: () => ({
    drawer: false,
    phishingData: [],
    originalPhishingData: [],
    menuItems: [
    ],
    userMenuItems: [
    ],
    loaderMenuItems: [
    ],
  }),
  methods: {
    handleMenuClick(item) {
      if (this.$router.currentRoute.path !== item.route) {
// eslint-disable-next-line
        this.$router.push(item.route).catch(err => {});
      }
      this.drawer = false;
    },
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    filterToday() {
      const today = new Date();

      this.phishingData = this.originalPhishingData.filter(item => {
        const [day, month, year] = item.date.split(".");
        const itemDate = new Date(year, month - 1, day);
        return itemDate.getFullYear() === today.getFullYear() && itemDate.getMonth() === today.getMonth() && itemDate.getDate() === today.getDate();
      });
    }
    ,
  },
  async created() {
  
},

};
</script>
