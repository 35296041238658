<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <!-- Обертка для скелетонов -->
        <template v-if="isLoading">
          <v-col
            cols="12" sm="6" md="4" lg="3"
            v-for="n in 4"
            :key="`skeleton-${n}`"
          >
            <v-skeleton-loader class="mx-auto" height="200px" width="100%" type="card" ></v-skeleton-loader>
          </v-col>
        </template>

        <!-- Обертка для отображения данных -->
        <template v-else>
          <v-col 
            v-for="(item, index) in tiles"
            :key="`tile-${index}`"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card :color="getColor()" dark class="d-flex pa-6">
              <v-row no-gutters>
                <v-col cols="4" class="d-flex justify-center align-center">
                  <v-icon x-large>{{ item.icon }}</v-icon>
                </v-col>
                <v-col cols="8">
                  <v-row>
                    <v-col cols="6">
                      <div class="overline mb-2">TODAY</div>
                      <div class="display-1 mb-4">{{ item.today }}</div>
                    </v-col>
                    <v-col cols="6">
                      <div class="overline mb-2">ALL</div>
                      <div class="display-1">{{ item.all }}</div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-main>
</template>





<script>
import axios from 'axios';

export default {
  data() {
    return {
      tiles: [],
      isLoading: true, // Состояние загрузки для отображения скелетона
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
  this.isLoading = true; // Устанавливаем isLoading в true перед запросом
  try {
    const token = this.$store?.state.token || localStorage.getItem('authToken');

    // Используем window.location.origin для получения текущего домена

    const hostname = window.location.hostname;
        const apiURL = `https://api.${hostname}`;

    const response = await axios.get(`${apiURL}/v1/give/loader`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    // Допустим, мы получаем данные и обновляем tiles здесь
    const data = response.data;
    // Предполагаем, что дата в данных API представлена строкой в формате "DD.MM.YYYY"
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Устанавливаем начало текущего дня

    // Функция для преобразования строки даты из API в объект Date
    const parseDate = dateString => {
      const [day, month, year] = dateString.split('.');
      return new Date(year, month - 1, day); // Месяцы начинаются с 0 в JavaScript
    };

    // Подсчёт количества элементов за сегодня
    const todayCount = data.filter(item => {
      const itemDate = parseDate(item.date); // Преобразуем дату из строки в Date
      return itemDate >= today;
    }).length;

    const allCount = data.length;
    this.tiles = [
      { icon: 'mdi-download', today: todayCount, all: allCount },
      { icon: 'mdi-wallet', today: 0, all: 0 },
      { icon: 'mdi-android', today: 0, all: 0 },
      { icon: 'mdi-cog', today: 0, all: 0 },
      // Дополните другими плитками, если это необходимо
    ];
  } catch (error) {
    console.error('Error fetching data:', error);
    // Обрабатываем ошибку здесь
  } finally {
    this.isLoading = false; // Устанавливаем isLoading в false после завершения загрузки
  }
},

  
    getColor() {
      // Метод для определения цвета карточки
      return this.$vuetify.theme.dark ? 'primary' : 'secondary';
    },
  },
};
</script>


<style scoped>
.v-main {
  margin-top: -16px; /* Adjust this value as needed */
}
