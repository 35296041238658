import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isAuthenticated: !!localStorage.getItem('authToken'), // Преобразуем наличие токена в булево значение
        token: localStorage.getItem('authToken')
    },
    mutations: {
        authenticate(state, token) {
            state.isAuthenticated = true;
            state.token = token;
            localStorage.setItem('authToken', token);
        },
        logout(state) {
            state.isAuthenticated = false;
            state.token = null;
            localStorage.removeItem('authToken');
        }
    },
    actions: {
        login({ commit }, token) {
            commit('authenticate', token);
        },
        logout({ commit }) {
            commit('logout');
        },
        initializeStore({ commit }) {
            const token = localStorage.getItem('authToken');
            if (token) {
                commit('authenticate', token);
            } else {
                commit('logout');
            }
        }
    }
});
