<template>
  <v-app class="overflow-hidden">
    <v-main class="align-center justify-center">
      <v-container fill-height>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="4" md="3">
            <v-card class="elevation-2 pa-5 rounded">
              <v-card-text>
                <div class="text-center mb-5 no-select">
                  <span style="font-size: 4em;">🍐</span>
                </div>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                      id="username"
                      label="Username"
                      v-model="username"
                      :rules="usernameRules"
                      required
                      underlined
                      class="mb-3"
                      hide-details="auto"
                  />
                  <v-text-field
                      id="password"
                      label="Password"
                      type="password"
                      v-model="password"
                      :rules="passwordRules"
                      required
                      underlined
                      class="mb-3"
                      hide-details="auto"
                  />
                  <v-btn
                      color="primary"
                      :disabled="!valid"
                      block
                      @click="submit"
                  >
                    Login
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer color="transparent" app>
      <v-spacer/>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="darkTheme">
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-btn>
        </template>
        <span>Change Theme</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="changeLocale">
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>
        <span>Change Language</span>
      </v-tooltip>
    </v-footer>
  </v-app>
</template>

<script>
/* eslint no-use-before-define: 0 */
import axios from 'axios'; // Убедитесь, что axios установлен
import enMessages from '/i18n/en';
import ruMessages from '/i18n/ru';

export default {
  data: () => ({
    valid: false,
    username: "",
    password: "",
    usernameRules: [
      v => !!v || 'Username is required',
    ],
    passwordRules: [
      v => !!v || 'Password is required',
      v => v.length >= 8 || 'Password must be at least 8 characters long',
    ],
    currentLocale: 'en'
  }),
  created() {
    this.$i18n.locale = this.currentLocale;
    this.setRules();
  },
  methods: {

    submit() {
      if (this.$refs.form.validate()) {

        // Используем window.location.origin для получения текущего домена
        const hostname = window.location.hostname;
        const apiURL = `https://api.${hostname}`;


        axios.post(`${apiURL}/v1/auth/login`, {
          username: this.username,
          password: this.password
        }).then(response => {
          const token = response.data.token;
          this.$store.commit('authenticate', token);
          this.$router.push('/main');
        }).catch(error => {
          console.error("Authentication error", error);
          alert('Login failed');
        });
      }
    },

    darkTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    changeLocale() {
      this.currentLocale = this.currentLocale === 'en' ? 'ru' : 'en';
      this.$i18n.locale = this.currentLocale;
      this.setRules();
    },
    setRules() {
      this.passwordRules = [
        v => !!v || this.$t('passwordRequired'),
        v => v.length >= 8 || this.$t('passwordLength'),
      ];
      this.usernameRules = [
        v => !!v || this.$t('usernameRequired'),
      ];
    }
  },
  i18n: {
    messages: {
      en: enMessages,
      ru: ruMessages
    }
  }
};
</script>

<style scoped>
.no-select {
  user-select: none;
}
.rounded {
  border-radius: 15px;
}
</style>
