<template>
    <div>
        <v-row align="center" class="mb-2">
          <v-col cols="12" sm="4">
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field v-model="dateRange.start" label="Start date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateRange.start" @input="menu = false"></v-date-picker>
            </v-menu>
          </v-col>
  
          <v-col cols="12" sm="4">
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field v-model="dateRange.end" label="End date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateRange.end" @input="menu2 = false"></v-date-picker>
            </v-menu>
          </v-col>
  
          <v-col cols="12" sm="4">
            <v-btn color="error" v-if="!isGuestStatRoute" class="mr-2" :disabled="selected.length === 0" @click="removeSelectedItems">Remove</v-btn>
            <v-btn color="primary" class="mr-2" @click="setToday">Today</v-btn>
            <v-btn color="primary" class="ml-2 mr-2" @click="fetchData">Refresh</v-btn>
            <v-btn color="primary" class="ml-2" @click="exportToCSV">Export</v-btn>
          </v-col>
        </v-row>
  
      <v-data-table
          :headers="headers"
          :items="filteredData"
          :items-per-page="5"
          class="elevation-1"
          show-select
          item-key="id"
          v-model="selected"
      >
      </v-data-table>
    </div>
  </template>
  
  
  <script>
import axios from 'axios';
import Papa from 'papaparse';

export default {
  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Date', value: 'date' },
      { text: 'Private', value: 'privateKey' },
      { text: 'Address', value: 'address' },
      { text: 'Service', value: 'service' },
    ],
    privateData: [],
    dateRange: {
      start: null,
      end: null,
    },
    menu: false,
    menu2: false,
    selected: [],
  }),
  methods: {
    async fetchData() {
      const hostname = window.location.hostname;
      const apiURL = `https://api.${hostname}/v1/give/private`;
      const token = this.$store.state.token;

      if (!token) {
        console.error("No authentication token available.");
        return;
      }

      try {
        const response = await axios.get(apiURL, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        this.privateData = response.data.map(item => ({
          id: item._id,
          date: item.date,
          privateKey: item.privateKey,
          address: item.address,
          service: item.service,
        }));
      } catch (error) {
        console.error("Error fetching private data from API:", error);
      }
    },
    setToday() {
      let today = new Date().toISOString().substr(0, 10);
      this.dateRange.start = today;
      this.dateRange.end = today;
    },
    exportToCSV() {
      if (this.selected.length === 0) {
        alert('No data selected for export.');
        return;
      }

      const csvData = Papa.unparse(this.selected);
      const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const csvUrl = window.URL.createObjectURL(csvBlob);

      const downloadLink = document.createElement('a');
      downloadLink.href = csvUrl;
      downloadLink.setAttribute('download', 'exported_data.csv');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    parseDate(input) {
      let parts = input.split('-');
      return new Date(parts[0], parts[1] - 1, parts[2]);
    },
    async removeSelectedItems() {
      try {
        const token = this.$store.state.token;

        if (!token) {
          console.error("3No authentication token available.");
          return;
        }
        const selectedAddress = this.selected.map(item => item.address);
        const hostname = window.location.hostname;
        const apiURL = `https://api.${hostname}`;

        const response = await axios.delete(`${apiURL}/v1/remove/private`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data: { address: selectedAddress }
        });

        if(response.status === 200) {
          this.fetchData();
          this.selected = [];
        } else {
          console.error('Server responded with status: ', response.status);
        }
      } catch(error) {
        console.error('Error removing selected items: ', error);
      }
    },
  },
  computed: {
    filteredData() {
      let filteredData = this.privateData;

      if (this.dateRange.start && this.dateRange.end) {
        let startDate = this.parseDate(this.dateRange.start);
        let endDate = this.parseDate(this.dateRange.end);
        endDate.setDate(endDate.getDate() + 1);

        filteredData = this.privateData.filter(item => {
          let itemDateParts = item.date.split(".");
          let itemDate = new Date(itemDateParts[2], itemDateParts[1] - 1, itemDateParts[0]);
          console.log (startDate)
          return itemDate >= startDate && itemDate < endDate;
        });
      } 
      
      return filteredData;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

  
  