<template>
  <v-container>
    <v-row>
      <v-col>
        <v-file-input :label="$t('loadFile')" @change="handleFile"></v-file-input>
        <v-btn :disabled="!selectedFile" @click="uploadFile">{{$t('load')}}</v-btn>
        <p v-if="uploadStatus">{{ uploadStatus }}</p>
        <a v-if="uploadedFileUrl" :href="uploadedFileUrl" target="_blank">{{ uploadedFileUrl }}</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      selectedFile: null,
      uploadStatus: '',
      uploadedFileUrl: '',
    };
  },
  methods: {
    handleFile(file) {
      this.selectedFile = file;
    },

    uploadFile() {
  if (!this.selectedFile) {
    this.uploadStatus = 'Файл не выбран';
    return;
  }

  const formData = new FormData();
  formData.append('file', this.selectedFile);


  // Используем window.location.origin для получения текущего домена
  const hostname = window.location.hostname;
        const apiURL = `https://api.${hostname}`;

  const url = `${apiURL}/v1/load/fileupload`;  // обновленный URL

  // Извлекаем токен аутентификации из Vuex store или localStorage
  const token = this.$store.state.token || localStorage.getItem('authToken');

  // Проверяем наличие токена перед выполнением запроса
  if (!token) {
    console.error("1No authentication token available.");
    this.uploadStatus = 'Требуется аутентификация';
    return;
  }

  this.uploadStatus = 'Загрузка...';

  fetch(url, {
    method: 'POST',
    body: formData,
    // Добавляем токен в заголовки запроса
    headers: {
      'Authorization': `Bearer ${token}`
    },
  })
      .then(response => response.json())
      .then(data => {
        this.uploadedFileUrl = data.url;
        this.uploadStatus = 'Файл успешно загружен!';
      })
      .catch(error => {
        console.error('Ошибка загрузки файла:', error);
        this.uploadStatus = 'Ошибка загрузки файла';
      });
},
  },
};
</script>
