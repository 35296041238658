export default {
    password: 'Password',
    login: 'Log in',
    changeTheme: 'Change Theme',
    changeLanguage: 'Change Language',
    passwordRequired: 'Password field is required',
    passwordLength: 'Password must be at least 8 characters',
    stats: 'Statistics',
    phishing: 'Phishing',
    apk: 'Apk',
    loader: 'Loader',
    settings: 'Settings',
    stealer: 'Stealer',
    profile: 'My Profile',
    mySettings: 'My Settings',
    logout: 'Logout',
    drawer: 'Drawer',
    home: 'Home',
    load: 'Load',
    enterlink: 'Enter link',
    add: 'Add',
    entercountry: 'Enter country',
    manycountry: 'You can select several countries',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    chooseClient: 'Choose client',
    service: 'Service',
    loadFile: 'Load file',
    mnemonic: 'Mnemonic',
    private: 'Private',
    json: 'Json'
};
